<template>
  <div class="xzjl">
      <ul>
        <li>
          <div class="yx">
            意向：
            <p>销售主管<span>6-7k</span></p>
          </div>
          <div class="tx">
            <img src="../../assets/img/news/img.png" alt="">
            <div class="text">
              <h4>Jasmine</h4>
              <p>女 | 22 | 无经验 | 本科</p>
            </div>
          </div>
          <div class="btn">
            <i class="del"></i>
            <span><img src="../../assets/img/Index_JobSeeker/icon22.png" alt="">
            13438422917</span>
            <span><img src="../../assets/img/Index_JobSeeker/icon23.png" alt="">
            1721693583@qq.com</span>
          </div>
        </li>
      </ul>
  </div>
</template>
<script>
export default {
  name: 'Resume',
  data() {
    return {
      value1: '',
    };
  },
  components: {
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.xzjl{
  overflow: hidden;
  li{
    background: #fff;
    padding: 16px;
    .yx{
      float: right;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      p{
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-top: 5px;
        span{
          font-size: 18px;
          font-weight: bold;
          color: #FF2647;
          margin-left: 40px;
        }
      }
    }
    .tx{
        overflow: hidden;
        img{
          width: 62px;
          height: 62px;
          border-radius: 50%;
          float: left;
          margin-right: 16px;
        }
        .text{
          overflow: hidden;
          padding-top: 8px;
          h4{
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 10px;
          }
          p{
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    .btn{
      clear: both;
      overflow: hidden;
      border-top: 1px solid #EEEEEE;
      margin: 16px 0 5px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      padding-top: 16px;
      span{
        margin-right: 24px;
        img{
          vertical-align: middle;
          margin-right: 2px;
          position: relative;
          top: -1px;
        }
      }
      i{
        float: right;
        width: 16px;
        height: 18px;
        cursor: pointer;
        background: transparent url(../../assets/img/recruit/icon-del.png) no-repeat left top;
      }
    }
  }
}
</style>
